import { useState } from "react";
import { X } from "lucide-react";
import ApiManager from "../api/ApiManager";

interface ProjectPhaseModalProps {
  isOpen: boolean;
  onClose: Function;
  data: any;
  onSave: Function;
}

export default function ProjectPhaseModal({
  isOpen,
  onClose,
  data,
  onSave,
}: ProjectPhaseModalProps) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setselectedImage] = useState("");
  const [imgName, setImgName] = useState("");

  const updateDetails = () => {
    const body = {
      start_date: startDate !== "" ? startDate : data?.start_date,
      end_date: endDate !== "" ? endDate : data.end_date,
      description: description !== "" ? description : data.description,
      image: selectedImage !== "" ? selectedImage : undefined,
    };

    ApiManager.updatePhase(body, data.id).then(() => {
      setStartDate("");
      setEndDate("");
      setDescription("");
      setselectedImage("");
      setImgName("");
      onSave();
      onClose(false);
    });
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setselectedImage(file);
      setImgName(file.name);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center px-4 py-3 border-b">
          <h2 className="text-xl font-semibold">{data?.name}</h2>
          <button
            onClick={() => onClose(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-4 space-y-2">
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Start date *
            </label>
            <input
              type="date"
              id="startDate"
              value={startDate ? startDate : data?.start_date}
              // min={new Date().toISOString().split("T")[0]}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-1 px-2 border rounded-md"
              required
            />
          </div>
          <div>
            <label
              htmlFor="endDate"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              End date *
            </label>
            <input
              type="date"
              id="endDate"
              value={endDate ? endDate : data?.end_date}
              min={startDate ? startDate : data?.start_date}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full p-1 px-2 border rounded-md"
              required
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description ? description : data?.description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border rounded-md"
              rows={3}
            />
          </div>
          <div className="flex flex-row items-center">
            <div className="flex items-center space-x-2">
              <input
                type="file"
                id="uploadImage"
                className="hidden"
                accept="image/*"
                onChange={handleImageChange}
              />
              <label
                htmlFor="uploadImage"
                className="px-4 py-1 bg-green-600 text-white rounded-md cursor-pointer hover:bg-gray-300"
              >
                Upload Image
              </label>
              <label className="block text-sm font-medium text-gray-500 mb-1">
                {imgName}
              </label>
            </div>
          </div>
        </div>
        <div className="p-6 border-t">
          <button
            onClick={() => updateDetails()}
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
}
