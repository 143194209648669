import { useState } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import ApiManager from "../../api/ApiManager";
import { Eye, EyeOff, LockKeyhole, Mail } from "lucide-react";

const Login = () => {
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const [emailError, setemailError] = useState(false);
  const [passwordError, setpasswordError] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const getLoginData = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setemailError(true);
    } else if (password.length < 8) {
      setpasswordError(true);
    }

    const body = {
      email: email,
      password: password,
    };

    ApiManager.adminSignIn(body).then((resp) => {
      if (resp?.data?.status == true) {
        console.log("data", resp.data.data);
        localStorage.setItem("userData", JSON.stringify(resp.data.data));
        addFCMTokenAPI();
        window.location.reload();
      } else {
        setpasswordError(true);
      }
    });
  };

  const addFCMTokenAPI = () => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const userId = userData?.id;
    const FCMToken = localStorage.getItem("fcmToken");
    const params = {
      userId: userId,
      fcmToken: FCMToken,
    };

    ApiManager.addFcmToken(params).then(
      (resp: { data: { status: boolean; data: any } }) => {
        if (resp?.data?.status == true) {
          console.log("data111", resp.data);
        }
      }
    );
  };

  return (
    <div className="bg-hero bg-cover bg-center h-screen w-screen flex items-center justify-center">
      {/* Left Side */}
      <div className="hidden md:flex md:w-1/2 flex-col items-center">
        <div className="text-center mb-8 mt-20">
          {/* Optional title for larger screens */}
        </div>
      </div>

      {/* Right Side (Login Form) */}
      <div className="w-full md:w-1/2 flex items-center justify-center">
        <div className="w-11/12 sm:w-4/5 md:w-full max-w-md bg-white rounded-lg shadow-md p-6 sm:p-8">
          <div className="mb-6 flex justify-center">
            <img src={logo} className="w-12 h-16 sm:w-16 sm:h-20" />
          </div>
          <h3 className="text-lg sm:text-xl font-semibold text-center mb-4">
            Admin Login
          </h3>

          {/* Form Fields */}
          <div className="mb-4 relative">
            <h2 className="mb-1">Email</h2>

            <div className="w-full px-3 py-2 flex items-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
              <Mail color="#7F7F7F" />
              <input
                type="email"
                placeholder="Email"
                className="w-full pl-3 focus:outline-none"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            {emailError && <Validation name={"please enter valid email"} />}
          </div>
          <div className="mb-3 relative">
            <h2 className="mb-1">Password</h2>

            <div className="w-full px-3 py-2 flex items-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
              <LockKeyhole color="#7F7F7F" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="w-full pl-3 focus:outline-none"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
            </div>
            <div
              onClick={() => setshowPassword(!showPassword)}
              className="absolute right-3 top-12 transform -translate-y-1/2"
            >
              {showPassword ? (
                <Eye className="text-gray-600" />
              ) : (
                <EyeOff className="text-gray-600" />
              )}
            </div>
            {passwordError && (
              <Validation name={"please enter a valid password"} />
            )}
          </div>
          <div className="text-end mb-3">
            <a
              href="#"
              className="text-sm text-red-600 hover:underline"
              onClick={() => navigate("/ForgotPassword")}
            >
              Forgot Password?
            </a>
          </div>
          <button
            className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => getLoginData()}
          >
            LOGIN
          </button>
          <div
            onClick={() => navigate("/customer/login")}
            className="mt-3 cursor-pointer flex justify-center"
          >
            <a className="text-center hover:text-red-600">login as customer</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

interface props {
  name: string;
}

const Validation = ({ name }: props) => {
  return <label className="text-[11px] text-red-500">{name}</label>;
};
